import React from "react";
import PaymentImage from "../../../../../assets/images/ssl-commerz.png";
import DikkhaImage from "../../../../../assets/images/logo_white.svg";
import BasisImage from "../../../../../assets/images/basis.png";

function Footer() {
  return (
    <footer className="bg-primary-dark py-16 mt-10">
      <div className="container mx-auto flex flex-col md:flex-row items-start justify-between px-4 md:px-12">
        <div className="flex flex-row md:flex-col items-start justify-between w-full md:w-1/4">
          <div className="md:mb-10 w-1/2 md:w-auto pr-2 md:pl-0">
            <div className="text-white font-semibold text-md mb-5">
              Quick Links
            </div>
            <ul className="text-grey-300 leading-8">
              <li>
                <a className="hover:text-white" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="hover:text-white" href="/">
                  FAQ
                </a>
              </li>
              <li>
                <a className="hover:text-white" href="/">
                  About Us
                </a>
              </li>
              <li>
                <a className="hover:text-white" href="/">
                  Dikkha Terms of Services
                </a>
              </li>
              <li>
                <a className="hover:text-white" href="/">
                  Dikkha Privacy Policy
                </a>
              </li>
              <li>
                <a className="hover:text-white" href="/">
                  Refund and Withdrawal Policy
                </a>
              </li>
            </ul>
          </div>

          <div className="w-1/2 md:w-auto pr-2 md:pr-0">
            <div className="text-white font-semibold text-md mb-5">Contact</div>
            <div className="text-grey-300 leading-8">
              <div className="flex flex-row items-center justify-start hover:text-white">
                <i className="fa-regular fa-envelope mr-2"></i>
                <a href="mailto:info@dikkha.com">info@dikkha.com</a>
              </div>
              <div className="flex flex-row items-center justify-start hover:text-white">
                <i className="fa-solid fa-phone-flip mr-2"></i>
                <a href="tel:+8801931449449">+8801931449449</a>
              </div>
              <div className="flex flex-row items-center justify-start hover:text-white">
                <i className="fa-brands fa-whatsapp mr-2"></i>
                <a href="tel:+8801304004001">+8801304004001 (WhatsApp)</a>
              </div>
              <div className="flex flex-row items-center justify-start">
                <i className="fa-regular fa-clock mr-2"></i>
                <div>Working Hour (10AM - 7PM)</div>
              </div>
              <div className="flex flex-row items-center justify-start">
                <i className="fa-regular fa-id-card mr-2 !leading-8 self-baseline"></i>
                <div>H-12-12, Road-05, Sector-10, Uttara, Dhaka</div>
              </div>
              <div className="flex flex-row items-center justify-start">
                <i className="fa-solid fa-briefcase mr-2"></i>
                <div>Trade License Number: 198615</div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-4/5 pl-0 md:pl-10">
          <img
            className="object-contain mb-3"
            src={PaymentImage}
            alt="sslcommerz"
          />

          <div className="flex flex-col md:flex-row items-center justify-between">
            <div>
              <img
                className="mx-auto md:mx-0 max-w-md object-contain"
                src={DikkhaImage}
                alt="Dikkha"
              />
              <img
                className="mx-auto md:mx-0 max-w-md object-contain mt-5"
                src={BasisImage}
                alt="basis"
              />
            </div>
            <div className="mt-10 md:mt-0">
              <div className="text-white font-semibold text-md mb-10">
                Get Connected With Us
              </div>
              <div className="text-grey-300 leading-8 flex flex-row justify-around items-center">
                <a className="hover:text-white" href="/">
                  <i className="fa-brands fa-facebook-square text-3xl"></i>
                </a>
                <a className="hover:text-white" href="/">
                  <i className="fa-brands fa-twitter-square text-3xl"></i>
                </a>
                <a className="hover:text-white" href="/">
                  <i className="fa-brands fa-youtube-square text-3xl"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
