import React from "react";
import { useRoutes } from "react-router-dom";
import { ROUTES } from "./routes";
import "./index.css";

function App() {
  const routes = useRoutes(ROUTES);
  return <>{routes}</>;
}

export default App;
