import { IOpinionProps } from "./components/Testimonial/Opinion";
import HappyImage from "../../assets/images/smiling-woman.png";

export const Reviews: IOpinionProps[] = [
  {
    image: HappyImage,
    imageName: "happy woman",
    comment:
      "Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really saves me time and effort. Skilline is exactly what our business has been lacking.",
    rating: 5,
    reviewCount: 12,
    reviewSource: "Yelp",
  },
  {
    image: HappyImage,
    imageName: "happy woman",
    comment:
      "Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really saves me time and effort. Skilline is exactly what our business has been lacking.",
    rating: 5,
    reviewCount: 10,
    reviewSource: "Yelp",
  },
  {
    image: HappyImage,
    imageName: "happy woman",
    comment:
      "Thank you so much for your help. It's exactly what I've been looking for. You won't regret it. It really saves me time and effort. Skilline is exactly what our business has been lacking.",
    rating: 5,
    reviewCount: 8,
    reviewSource: "Yelp",
  },
];
