import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { TEACHER, STUDENT } from './constant';

export interface INavigation {
    id: string;
    url?: string;
    title: string;
    icon?: any;
    roles: string[];
    children?: INavigation[];
    isChild?: boolean;
}

const iconSize = 20;

export const navigation: INavigation[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        icon: {
            class: DashboardIcon,
            props: {
                sx: { fontSize: iconSize }
            }
        },
        roles: [TEACHER, STUDENT],
        url: '/dashboard'
    },
    {
        id: 'profile',
        title: 'View Profile',
        icon: {
            class: AccountCircleOutlinedIcon,
            props: {
                sx: { fontSize: iconSize }
            }
        },
        roles: [TEACHER, STUDENT],
        url: '/profile'
    },
    {
        id: 'tuition-jobs',
        title: 'Tuition Jobs',
        icon: {
            class: WorkOutlinedIcon,
            props: {
                sx: { fontSize: iconSize }
            }
        },
        roles: [TEACHER],
        url: '/tuition-jobs'
    },
    {
        id: 'tuition-activity',
        title: 'My Tuition Activity',
        icon: {
            class: HistoryEduIcon,
            props: {
                sx: { fontSize: iconSize }
            }
        },
        roles: [TEACHER, STUDENT],
        url: '/tuition-activity'
    },
    {
        id: 'wallet',
        title: 'My Wallet',
        icon: {
            class: AccountBalanceWalletIcon,
            props: {
                sx: { fontSize: iconSize }
            }
        },
        roles: [TEACHER],
        url: '/wallet'
    },
    {
        id: 'certificates',
        title: 'Certificates',
        icon: {
            class: CardMembershipIcon,
            props: {
                sx: { fontSize: iconSize }
            }
        },
        roles: [TEACHER],
        url: '/certificates'
    }
]