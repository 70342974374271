import React, { memo } from "react";
import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  sideBarWidth,
  topBarHeight,
  transitionDuration,
} from "../layout.config";
import { Colors, WINDOW_LARGE } from "../../../constant";
import { useWindowSize } from "../../../hooks/useWindowSize";
import Avatar from "../../Avatar";
import Menu from "../../Menu";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/features/user/userThunks";
import { useSnackbar } from "notistack";

export interface ITopBarProps {
  onMenuButtonClick: () => void;
  isSidebarOpen: boolean;
}

function Topbar({ onMenuButtonClick, isSidebarOpen }: ITopBarProps) {
  const size = useWindowSize();
  const userState = useAppSelector((state) => state.user).data;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<
    { id: string; element: null | HTMLElement }[]
  >([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    const foundIndex = anchorEl.findIndex((e) => e.id === id);
    if (foundIndex >= 0) {
      anchorEl[foundIndex].element = event.currentTarget;
      setAnchorEl(anchorEl);
    } else {
      setAnchorEl([...anchorEl, { id, element: event.currentTarget }]);
    }
  };

  const handleClose = (id: string) => {
    // const foundIndex = anchorEl.findIndex((e) => e.id === id);
    // if (foundIndex >= 0) {
    //     anchorEl[foundIndex].element = null;
    //     setAnchorEl(anchorEl);
    // }
    setAnchorEl([]);
  };

  const getAnchorEl = (id: string): null | HTMLElement => {
    const foundIndex = anchorEl.findIndex((e) => e.id === id);
    if (foundIndex >= 0) return anchorEl[foundIndex].element;
    return null;
  };

  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => enqueueSnackbar("Logged Out", { variant: "success" }))
      .finally(() => {
        navigate("home");
      });
  };

  const menuItemStyle: SxProps = {
    width: "100%",
    alignItems: "center",
    borderRadius: "0.35rem",
    margin: "0.5rem 0",
    color: "primary.light",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "white",
    },
  };

  const styles: { [key: string]: SxProps } = {
    appBar: {
      height: topBarHeight,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "0 0 10px 10px",
      boxShadow: `0px 1px 5px 0px ${Colors.secondary.main}`,
      background: "white",
    },
    menuButtonStyles: {
      marginLeft: size === WINDOW_LARGE && isSidebarOpen ? sideBarWidth : "0px",
      transition: `margin-left ${transitionDuration}ms`,
    },
    menuIconStyles: {
      color: "primary.dark",
    },
    menuItem: { "&.MuiMenuItem-root": { ...menuItemStyle } },
    menuItemActive: {
      "&.MuiMenuItem-root": {
        ...menuItemStyle,
        backgroundColor: "primary.main",
        color: "white",
      },
    },
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        ...styles.appBar,
        zIndex:
          size === WINDOW_LARGE
            ? (theme: Theme) => theme.zIndex.drawer
            : (theme: Theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <IconButton
          size="large"
          sx={{ ...styles.menuButtonStyles }}
          onClick={onMenuButtonClick}
        >
          <MenuIcon sx={{ ...styles.menuIconStyles }} />
        </IconButton>

        <Box>
          <Box>
            <button
              className="flex flex-row justify-between items-center"
              onClick={(e) => handleClick(e, "account-menu")}
            >
              <Avatar />
              <span className="text-primary-dark ml-2">{`${
                userState?.first_name || ""
              } ${userState?.last_name || ""}`}</span>
              {anchorEl.length ? (
                <ExpandMoreIcon color="primary" />
              ) : (
                <ExpandLessIcon color="primary" />
              )}
            </button>
          </Box>

          <Menu
            id="account-menu"
            anchorEl={getAnchorEl("account-menu")}
            handleClick={() => handleClose("account-menu")}
            handleClose={() => handleClose("account-menu")}
          >
            <Box
              sx={{
                minWidth: "280px",
                padding: "0 0.5rem",
              }}
            >
              <MenuItem component={NavLink} to={"/"}>
                <Typography variant="subtitle1" component="div">
                  View Profile
                </Typography>
              </MenuItem>

              <MenuItem component={NavLink} to={"/"}>
                <Typography variant="subtitle1" component="div">
                  Settings
                </Typography>
              </MenuItem>

              <MenuItem onClick={handleLogout}>
                <Typography variant="subtitle1" component="div">
                  Log Out
                </Typography>
              </MenuItem>
            </Box>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default memo(Topbar);
