import React from "react";
import Layout from "./components/Layout";
import Header from "./components/Header";
import Services from "./components/Services";
import AboutUs from "./components/AboutUs";
import Testimonial from "./components/Testimonial";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Dikkha | Home</title>
      </Helmet>
      <Header />
      <Services />
      <AboutUs />
      <Testimonial />
    </Layout>
  );
}

export default Home;
