import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

function FeatureGuard({
  accessibleRoles,
  redirectUrl,
  children,
}: PropsWithChildren<{ accessibleRoles: string[]; redirectUrl?: string }>) {
  const userState = useAppSelector((state) => state.user);
  const canAccess = accessibleRoles.some((role) =>
    userState?.data?.roles?.includes(role)
  );

  if (canAccess) {
    return <>{children}</>;
  }

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  return <></>;
}

export default FeatureGuard;
