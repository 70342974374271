import { INavigation } from "../navigation";

export function filterFeatureGuard(userRoles: string[], navigation: INavigation[]): INavigation[] {
    const roles = userRoles
    return navigation.filter(function f(item): any {
        ///if no roles needed
        if (!item.roles) {
            return true;
        }
        //if child then measure guard first from child 
        if (item.children?.length) {
            return (item.children = item.children.filter(f)).length
        }
        if (item.roles.some(roleName => roles.includes(roleName))) {
            return true;
        }
        return false;
    })
}