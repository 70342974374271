import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Colors } from "../../constant";

function Spinner() {
  return (
    <CircularProgress
      sx={{
        color: Colors.primary.main,
      }}
      size={100}
      thickness={3}
    />
  );
}

export default Spinner;
