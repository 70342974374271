import React, { createElement } from "react";
import List from "@mui/material/List";
import { Collapse } from "@mui/material";
import SidebarItem from "./SidebarItem";
import { IFlatData } from "../../../../utils/TreeFlattener";
import { INavigation } from "../../../../navigation";

const SidebarNavigation = ({
    navigationData,
    isSubmenu = false,
    open,
    selectedNavPath,
}: {
    navigationData: INavigation[];
    isSubmenu?: boolean;
    open?: boolean;
    selectedNavPath: IFlatData<INavigation>[];
}) => {
    const Navigation = (props: any) => {
        if (!isSubmenu) return createElement(List, { ...props });
        return createElement(Collapse, {
            ...props,
            in: open,
            unmountOnExit: true,
            timeout: "auto",
        });
    };

    return (
        <Navigation>
            {navigationData.map((data) => (
                <SidebarItem
                    key={data.id}
                    data={data}
                    selectedNavPath={selectedNavPath}
                />
            ))}
        </Navigation>
    );
};

export default SidebarNavigation;
