import { USER_TOKEN } from "../constant";

const saveData = (key: string, value: any) => {
    typeof window !== 'undefined' && localStorage.setItem(key, value);
};

const getData = (key: string) => {
    const value = typeof window !== 'undefined' && localStorage.getItem(key);
    return value ? value : null;
};

const deleteData = (key: string) => {
    typeof window !== 'undefined' && localStorage.removeItem(key);
    return localStorage.getItem(key) ? false : true
};

export const saveToken = (value: string) => {
    saveData(USER_TOKEN, value);
};

export const getToken = () => {
    const value = getData(USER_TOKEN);
    return value ? value : "";
};

export const deleteToken = () => {
    return deleteData(USER_TOKEN);
};


