import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError, AxiosResponse } from 'axios';
import { GET_LOGGEDIN_USER, USER_LOGIN, USER_LOGOUT, USER_REGISTER } from '../../../api';
import { deleteToken, getToken, saveToken } from '../../../utils/localStorage';
import { formatErrors } from '../../helpers';
import { IGetLoggedInUserResponse, IUserLogin, IUserLoginResponse, IUserRegister, IUserRegisterResponse } from './contracts';

export const LOGIN_ACTION = 'user/login';
export const login = createAsyncThunk(
    LOGIN_ACTION,
    async (data: IUserLogin, { rejectWithValue, dispatch }) => {
        try {
            const response = await axios.post<IUserLogin, AxiosResponse<IUserLoginResponse>>(USER_LOGIN, data);
            const access_token = response.data.access_token;
            const token_type = response.data.token_type;
            saveToken(`${token_type} ${access_token}`);
            dispatch(getLoggedInUser());
            return response.data.message;
        } catch (error) {
            const err = error as AxiosError<{ message: string }>;
            throw rejectWithValue(err.response?.data?.message);
        }
    }
)

export const REGISTER_ACTION = 'user/register';
export const register = createAsyncThunk(
    REGISTER_ACTION,
    async (data: IUserRegister, { rejectWithValue }) => {
        try {
            const response = await axios.post<IUserRegister, AxiosResponse<IUserRegisterResponse>>(USER_REGISTER, data);
            return response?.data?.message;
        } catch (error) {
            const err = error as AxiosError<any>;
            throw rejectWithValue(formatErrors(err.response?.data));
        }
    }
)

export const GET_LOGGEDIN_USER_ACTION = 'user/me';
export const getLoggedInUser = createAsyncThunk(
    GET_LOGGEDIN_USER_ACTION,
    async (_, { rejectWithValue }) => {
        try {
            const token = getToken();
            const response = await axios.get<null, AxiosResponse<IGetLoggedInUserResponse>>(GET_LOGGEDIN_USER, {
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            });
            return response?.data?.data;
        } catch (error) {
            const err = error as AxiosError<{ message: string }>;
            throw rejectWithValue(err.response?.data.message);
        }
    }
)

export const LOGOUT_ACTION = 'user/logout';
export const logout = createAsyncThunk(
    LOGOUT_ACTION,
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const token = getToken();
            const response = await axios.get<null, AxiosResponse<{ message: string }>>(USER_LOGOUT, {
                headers: {
                    Accept: "application/json",
                    Authorization: token
                }
            });
            deleteToken();
            dispatch(getLoggedInUser())
            return response.data.message
        } catch (error) {
            const err = error as AxiosError<{ message: string }>;
            throw rejectWithValue(err.response?.data.message);
        }
    }
)