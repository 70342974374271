import React from "react";
import TutorImage from "../../../../assets/images/become_tutor.png";

function AboutUs() {
  return (
    <section className="lg:mt-64 xl:mt-24">
      <div className="relative w-ful container mx-auto px-4 md:px-12">
        <div className="font-semibold text-center md:text-left mb-10">
          <span className="text-primary text-3xl md:text-[3.375rem] leading-[5rem]">
            Dikkha
          </span>
          &nbsp;
          <span className="text-green text-3xl md:text-[3.375rem] leading-[5rem]">
            Classroom
          </span>
        </div>

        <div className="flex items-center md:float-right sm:mx-3 mx-5 video-player object-contain bg-primary-light rounded">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/uH2CkZinkA0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        {/* <div className="video-player md:float-right mr-5 ml-5 md:ml-20 mb-5 md:w-1/2 h-[30rem] object-contain bg-primary-light rounded">
          <div className="hidden md:block absolute w-[1.875rem] h-[1.875rem] bg-primary-light rounded-[50%] top-1/2 -left-[10%] z-[-1]"></div>
          
        </div> */}

        <div className="text-center md:text-left font-medium mt-10">
          <span className="about-us text-secondary-dark text-[2rem]">
            Everything you can do in a physical classroom,
          </span>
          &nbsp;
          <span className="text-primary-light text-[2rem]">
            you can do in Dikkha Classroom
          </span>
        </div>
        <p className="md:mr-8 text-grey-300 text-xl mt-8 text-justify">
          Dikkha Online Limited is providing a platform for blended education ,
          includes online offline education. Dikkha developed the first Online
          Education platform in Bangladesh which provides a “real-time online
          virtual classroom” platform for teachers and students to teach and
          learn simultaneously. Since we are committed to provide the best
          tutoring experience, we try to provide various opportunities and open
          every possible door for you. Register now and join our online
          education and tutoring service.
        </p>
      </div>

      <div className="relative w-full mt-28 container mx-auto px-4 md:px-12 flex flex-col items-center justify-between md:flex-row">
        <img
          className="object-contain 2xl:max-w-[964px] xl:max-w-[800px] lg:max-w-[600px] md:max-w-sm"
          src={TutorImage}
          alt="Become a tutor"
        />

        <div className="max-w-[40rem]">
          <div className="font-semibold text-center md:text-right">
            <span className="text-primary text-3xl md:text-[3.375rem] leading-[3rem] md:leading-[5rem] ">
              Want To Become A
            </span>
            &nbsp;
            <span className="text-green text-3xl md:text-[3.375rem] leading-[3rem] md:leading-[5rem] ">
              Tutor?
            </span>
          </div>
          <p className="text-justify mt-5 text-xl text-grey-300">
            className has a dynamic set of teaching tools built to be deployed
            and used during className. Teachers can handout assignments in
            real-time for students to complete and submit.
          </p>
          <div className="w-full mb-2 mt-10 float-right">
            <a
              href="/"
              className="mx-auto md:ml-auto md:mx-0 flex flex-row justify-center items-center w-64 h-14 md:w-72 md:h-16 bg-primary-light rounded-3xl text-white font-semibold"
            >
              Become A Tutor
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutUs;
