import { createSlice } from '@reduxjs/toolkit'
import { IReducerState } from '../../common';
import { formatErrors } from '../../helpers';
import { IUserData } from './contracts';
import { getLoggedInUser, login, logout, register } from './userThunks';

export interface IUserReducerState extends IReducerState<IUserData | null> {
    isLoggedIn: boolean;
}
// Define the initial state using that type
const initialState: IUserReducerState = {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    isLoggedIn: false,
    message: '',
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = initialState.isLoading;
            state.isSuccess = initialState.isSuccess;
            state.isError = initialState.isError;
            state.message = initialState.message;
        },
    },
    extraReducers: (builder) => {
        // Login Actions Start
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
        })
        builder.addCase(login.fulfilled, (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
        })
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload as string;
        })

        // Register Actions Start
        builder.addCase(register.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
        })
        builder.addCase(register.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.message = action.payload;
        })
        builder.addCase(register.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = formatErrors(action.payload as any)[0];
        })

        // Get Loggedin User Actions Start
        builder.addCase(getLoggedInUser.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
        })
        builder.addCase(getLoggedInUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.isLoggedIn = true;
            state.data = action.payload;
        })
        builder.addCase(getLoggedInUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.isLoggedIn = false;
            state.message = action.payload as string;
        })

        //Logout Actions
        builder.addCase(logout.fulfilled, (state) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.data = null;
        })
        builder.addCase(logout.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.payload as string;
        })
    }
})

export const { reset } = userSlice.actions;
export default userSlice.reducer;