import React, { Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import {
  ACTIVE_JOBS,
  APPLIED_JOBS,
  APPROVED_JOBS,
  COMPLETED_JOBS,
  JOB_TYPE_URL_MAP,
  OFFERED_JOBS,
  PENDING_JOBS,
  STUDENT,
  TEACHER,
} from "./constant";

import Layout from "./components/Layout";
import Spinner from "./components/Spinner";
import Home from "./pages/landing/Home";

const PrivateRoute = React.lazy(() => import("./components/PrivateRoute"));
const PublicRoute = React.lazy(() => import("./components/PublicRoute"));
const FeatureGuard = React.lazy(() => import("./components/FeatureGuard"));

const Auth = React.lazy(() => import("./pages/Auth"));
const Login = React.lazy(() => import("./pages/Auth/Login"));
const Register = React.lazy(() => import("./pages/Auth/Register"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));

const Profile = React.lazy(() => import("./pages/Profile"));
const ProfileEdit = React.lazy(() => import("./pages/Profile/ProfileEdit"));
const TuitionEdit = React.lazy(
  () => import("./pages/Profile/ProfileEdit/TuitionEdit")
);
const EducationEdit = React.lazy(
  () => import("./pages/Profile/ProfileEdit/EducationEdit")
);
const PersonalEdit = React.lazy(
  () => import("./pages/Profile/ProfileEdit/PersonalEdit")
);
const CredentialEdit = React.lazy(
  () => import("./pages/Profile/ProfileEdit/CredentialEdit")
);

const TuitionJobs = React.lazy(() => import("./pages/TuitionJobs"));

const TuitionActivity = React.lazy(() => import("./pages/TuitionActivity"));
const JobList = React.lazy(() => import("./pages/TuitionActivity/JobList"));

const Wallet = React.lazy(() => import("./pages/Wallet"));
const Certificates = React.lazy(() => import("./pages/Certificates"));

const Page404 = React.lazy(() => import("./pages/404Page"));

const fallBack = (
  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
    <Spinner />
  </div>
);

export const ROUTES: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="home" />,
  },
  {
    path: "home",
    element: (
      <Suspense fallback={fallBack}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: "404",
    element: (
      <Suspense fallback={fallBack}>
        <Page404 />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: (
      <PrivateRoute accessibleRoles={[TEACHER, STUDENT]}>
        <Layout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to={"/dashboard"} />,
      },
      {
        path: "dashboard",
        element: (
          <FeatureGuard accessibleRoles={[TEACHER, STUDENT]}>
            <Dashboard />
          </FeatureGuard>
        ),
      },
      {
        path: "profile",
        element: (
          <FeatureGuard accessibleRoles={[TEACHER]}>
            <Profile />
          </FeatureGuard>
        ),
        children: [
          {
            path: "edit",
            element: <ProfileEdit />,
            children: [
              {
                path: "tution",
                element: <TuitionEdit />,
              },
              {
                path: "education",
                element: <EducationEdit />,
              },
              {
                path: "personal",
                element: <PersonalEdit />,
              },
              {
                path: "credential",
                element: <CredentialEdit />,
              },
            ],
          },
        ],
      },
      {
        path: "tuition-jobs",
        element: (
          <FeatureGuard accessibleRoles={[TEACHER]} redirectUrl="/404">
            <TuitionJobs />
          </FeatureGuard>
        ),
      },
      {
        path: "tuition-activity",
        element: (
          <FeatureGuard accessibleRoles={[TEACHER, STUDENT]} redirectUrl="/404">
            <TuitionActivity />
          </FeatureGuard>
        ),
        children: [
          {
            path: "",
            element: <Navigate to={JOB_TYPE_URL_MAP[APPLIED_JOBS].url} />,
          },
          {
            path: JOB_TYPE_URL_MAP[APPLIED_JOBS].url,
            element: (
              <FeatureGuard
                accessibleRoles={[TEACHER]}
                redirectUrl={`../${JOB_TYPE_URL_MAP[PENDING_JOBS].url}`}
              >
                <JobList jobType={APPLIED_JOBS} />
              </FeatureGuard>
            ),
          },
          {
            path: JOB_TYPE_URL_MAP[OFFERED_JOBS].url,
            element: (
              <FeatureGuard accessibleRoles={[TEACHER]} redirectUrl="/404">
                <JobList jobType={OFFERED_JOBS} />
              </FeatureGuard>
            ),
          },
          {
            path: JOB_TYPE_URL_MAP[ACTIVE_JOBS].url,
            element: (
              <FeatureGuard accessibleRoles={[TEACHER, STUDENT]}>
                <JobList jobType={ACTIVE_JOBS} />
              </FeatureGuard>
            ),
          },
          {
            path: JOB_TYPE_URL_MAP[COMPLETED_JOBS].url,
            element: (
              <FeatureGuard accessibleRoles={[TEACHER, STUDENT]}>
                <JobList jobType={COMPLETED_JOBS} />
              </FeatureGuard>
            ),
          },
          {
            path: JOB_TYPE_URL_MAP[PENDING_JOBS].url,
            element: (
              <FeatureGuard accessibleRoles={[STUDENT]} redirectUrl="/404">
                <JobList jobType={PENDING_JOBS} />
              </FeatureGuard>
            ),
          },
          {
            path: JOB_TYPE_URL_MAP[APPROVED_JOBS].url,
            element: (
              <FeatureGuard accessibleRoles={[STUDENT]} redirectUrl="/404">
                <JobList jobType={APPROVED_JOBS} />
              </FeatureGuard>
            ),
          },
        ],
      },
      {
        path: "wallet",
        element: (
          <FeatureGuard accessibleRoles={[TEACHER]} redirectUrl="/404">
            <Wallet />
          </FeatureGuard>
        ),
      },
      {
        path: "certificates",
        element: (
          <FeatureGuard accessibleRoles={[TEACHER]} redirectUrl="/404">
            <Certificates />
          </FeatureGuard>
        ),
      },
    ],
  },
  {
    path: "auth",
    element: (
      <Suspense fallback={fallBack}>
        <PublicRoute>
          <Auth />
        </PublicRoute>
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: <Navigate to={"login"} />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={fallBack}>
        <Page404 />
      </Suspense>
    ),
  },
];
