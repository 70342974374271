import React from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Reviews } from "../../pageContents";
import { Pagination, Controller } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import Opinion from "./Opinion";

const SwiperButtonNext = () => {
  const swiper = useSwiper();
  return (
    <button
      className="hidden lg:block next z-10 bg-white absolute w-16 h-16 rounded-[50%] shadow-[0px_0px_19px_-1px_theme(colors.grey.300)] text-lg text-primary-light right-[16%] top-[30%]"
      onClick={() => swiper.slideNext()}
    >
      <i className="fa-solid fa-chevron-right"></i>
    </button>
  );
};

const SwiperButtonPrev = () => {
  const swiper = useSwiper();
  return (
    <button
      className="hidden lg:block previous z-10 bg-white absolute w-16 h-16 rounded-[50%] shadow-[0px_0px_19px_-1px_theme(colors.grey.300)] text-lg text-primary-light left-[2%] top-[30%]"
      onClick={() => swiper.slidePrev()}
    >
      <i className="fa-solid fa-chevron-left"></i>
    </button>
  );
};

function Testimonial() {
  return (
    <section>
      <div className="w-full mt-28 container mx-auto px-4 md:px-12 flex flex-col items-center justify-between md:flex-row">
        <div className="relative order-1 md:order-2 max-w-[25rem] md:max-w-sm lg:max-w-[35rem]">
          <Swiper
            className="relative"
            modules={[Pagination, Controller]}
            id="opinions"
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
          >
            {Reviews.map((review, index) => (
              <SwiperSlide
                key={index}
                className="relative bg-white min-h-[24rem] lg:min-h-[45rem]"
              >
                <Opinion {...review} />
              </SwiperSlide>
            ))}
            <SwiperButtonPrev />
            <SwiperButtonNext />
          </Swiper>
        </div>

        <div className="max-w-[40rem] order-2 md:order-1 mr-0 md:mr-5">
          <div className="font-semibold text-center md:text-left">
            <span className="text-primary text-3xl md:text-[3.375rem] leading-[3rem] md:leading-[5rem] ">
              Opinions of
            </span>
            &nbsp;&nbsp;
            <span className="text-green text-3xl md:text-[3.375rem] leading-[3rem] md:leading-[5rem] ">
              Guardians
            </span>
          </div>
          <p className="text-xl text-justify mt-5 text-grey-300">
            <span className="text-primary-light text-xl">Dikkha</span> has
            got more than 100k positive ratings from our users around the world.
          </p>
          <p className="text-justify mt-5 text-xl text-grey-300">
            Some of the students and teachers were greatly helped by the Dikkha.
          </p>
          <p className="text-justify mt-5 text-xl text-grey-300">
            Is your child too? Please give your assessment.
          </p>
          <div className="w-full mb-2 mt-10">
            <a
              href="/"
              className="mx-auto md:mr-auto md:mx-0 flex flex-row justify-center items-center w-64 h-14 md:w-72 md:h-16 bg-secondary-light rounded-3xl text-white font-semibold"
            >
              Write Assessment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
