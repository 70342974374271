import React, { useEffect, useState, memo, Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, SxProps } from "@mui/material";
import Topbar from "./Topbar";

import {
  backgroundColor,
  sideBarWidth,
  topBarHeight,
  transitionDuration,
} from "./layout.config";
import Sidebar from "./Sidebar";
import { WINDOW_LARGE, WINDOW_MOBILE, WINDOW_TABLET } from "../../constant";
import { useWindowSize } from "../../hooks/useWindowSize";
import Spinner from "../Spinner";

function Layout() {
  const size = useWindowSize();
  const [sideBarOpen, setSidebarOpen] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    if (size === WINDOW_LARGE) {
      setSidebarOpen(true);
    } else if (size === WINDOW_MOBILE || size === WINDOW_TABLET) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [size, location]);

  const handleSidebarButton = () => {
    setSidebarOpen((o) => !o);
  };

  const containerStyle: SxProps = {
    marginLeft: size === WINDOW_LARGE && sideBarOpen ? sideBarWidth : "0",
    padding: `calc(1rem + ${topBarHeight}) 1rem 1rem 1rem`,
    transitionDuration: `${transitionDuration}ms`,
    height: "100vh",
    background: backgroundColor,
    overflow: "auto",
  };

  return (
    <Box sx={{ background: backgroundColor }}>
      <Topbar
        onMenuButtonClick={handleSidebarButton}
        isSidebarOpen={sideBarOpen}
      />
      <Sidebar
        open={sideBarOpen}
        onBackDropClick={() => setSidebarOpen(false)}
      />
      <Box sx={{ ...containerStyle }}>
        <div className="w-full max-w-[1980px] mx-auto">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </div>
      </Box>
    </Box>
  );
}

export default memo(Layout);
