import React from "react";
import { Menu as MuiMenu } from "@mui/material";

function Menu({
  children,
  id,
  anchorEl,
  handleClick,
  handleClose,
}: React.PropsWithChildren<{
  id: string;
  anchorEl: null | HTMLElement;
  handleClick: (e?: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
}>) {
  const open = Boolean(anchorEl);

  return (
    <MuiMenu
      anchorEl={anchorEl}
      id={id}
      open={open}
      onClose={handleClose}
      onClick={handleClick}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
          mt: 1,
          borderRadius: "10px",
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </MuiMenu>
  );
}

export default Menu;
