import React from "react";
import {
  WindowSize,
  WINDOW_LARGE,
  WINDOW_MOBILE,
  WINDOW_TABLET,
} from "../constant";

export function useWindowSize(): WindowSize {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (windowWidth > 1440) return WINDOW_LARGE;
  else if (windowWidth < 768) return WINDOW_MOBILE;
  else if (windowWidth >= 768 && windowWidth <= 1024) return WINDOW_TABLET;
  else return WINDOW_LARGE;
}
