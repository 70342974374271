import React, { memo, useEffect, useState } from "react";
import SidebarNavigation from "./SidebarNavigation";
import {
  Button,
  Checkbox,
  Drawer,
  DrawerProps,
  FormControlLabel,
  FormGroup,
  SxProps,
} from "@mui/material";
import {
  sideBarWidth,
  topBarHeight,
  transitionDuration,
} from "../layout.config";
import { INavigation, navigation } from "../../../navigation";
import { filterFeatureGuard } from "../../../utils/filterFeatureGuard";
import { IFlatData, TreeFlattener } from "../../../utils/TreeFlattener";
import { WINDOW_LARGE, WINDOW_MOBILE, WINDOW_TABLET } from "../../../constant";
import { useWindowSize } from "../../../hooks/useWindowSize";
import DikkhaImage from "../../../assets/images/logo_white.svg";
import { useAppSelector } from "../../../redux/hooks";
import Avatar from "../../Avatar";
import { NavLink } from "react-router-dom";
import FeatureGuard from "../../FeatureGuard";

function Sidebar({
  open,
  onBackDropClick,
}: {
  open: boolean;
  onBackDropClick?: () => void;
}) {
  const size = useWindowSize();
  const [nav, setNav] = useState<INavigation[]>([]);
  const [flatNav, setFlatNav] = useState<IFlatData<INavigation>[]>([]);
  const [drawerVariant, setDrawerVariant] =
    useState<DrawerProps["variant"]>("persistent");

  const userState = useAppSelector((state) => state.user);
  useEffect(() => {
    const filteredNav = filterFeatureGuard(
      userState.data?.roles || [],
      navigation
    );
    const flatNavTree = new TreeFlattener<INavigation>(filteredNav)
      .treeDataFlat;
    setFlatNav(flatNavTree);
    setNav(filteredNav);
  }, [userState.data?.roles]);

  useEffect(() => {
    if (size === WINDOW_LARGE) {
      setDrawerVariant("persistent");
    } else {
      setDrawerVariant("temporary");
    }
  }, [size]);

  const styles: { [key: string]: SxProps } = {
    drawer: {
      width: sideBarWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: {
        width: sideBarWidth,
        boxSizing: "border-box",
        paddingTop:
          size === WINDOW_TABLET || size === WINDOW_MOBILE ? topBarHeight : 0,
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        backgroundColor: "primary.main",
        borderRadius: "0 15px 15px 0",
      },
    },
    formGroup: {
      "&.MuiFormGroup-root": {
        "& .MuiTypography-root": {
          fontSize: "0.75rem",
        },
        "& .MuiFormControlLabel-root": {
          width: "max-content",
        },
      },
    },
    checkbox: {
      width: "3px",
      height: "3px",
      marginLeft: "3px",
      color: "white",
      "&.Mui-checked": {
        color: "white",
      },
    },
  };

  return (
    <Drawer
      variant={drawerVariant}
      transitionDuration={{
        enter: transitionDuration,
        exit: transitionDuration,
      }}
      sx={{
        ...styles.drawer,
      }}
      open={open}
      ModalProps={{ onBackdropClick: onBackDropClick }}
    >
      <NavLink to={"home"} className="mx-auto mt-8">
        <img className="object-contain w-20" src={DikkhaImage} alt="Dikkha" />
      </NavLink>

      <div className="mx-auto mt-10">
        <Avatar styles={{ width: "10rem", height: "10rem" }} />
      </div>

      <div className="mx-auto mt-2 text-white font-bold">
        {`${userState.data?.first_name || ""} ${
          userState.data?.last_name || ""
        }`}
      </div>
      <div className="mx-auto text-white text-xs">{`${userState.data?.email}`}</div>

      <div className="mx-auto mt-2">
        <SidebarNavigation navigationData={nav} selectedNavPath={flatNav} />
      </div>

      <FeatureGuard accessibleRoles={["teacher"]}>
        <div className="mx-auto mt-5 w-10/12">
          <Button className="bg-green text-white px-5 py-3 rounded-xl hover:bg-green hover:text-white w-full">
            Enter Classroom: 667380
          </Button>
        </div>

        <div className="w-10/12 mx-auto mt-4 border-b-2 border-white text-sm font-bold text-white">
          Classroom Settings
        </div>

        <FormGroup
          sx={styles.formGroup}
          className="flex flex-row items-center justify-between w-10/12 mx-auto text-white"
        >
          <div className="text-xs">Start Class With:</div>
          <div>
            <FormControlLabel
              control={<Checkbox size="small" sx={styles.checkbox} />}
              label="Video"
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Checkbox size="small" sx={styles.checkbox} />}
              label="Audio"
              labelPlacement="start"
            />
          </div>
        </FormGroup>

        <div className="mx-auto mt-2 text-white font-bold text-left w-10/12">
          <NavLink to={"/"} className="text-left w-full text-sm">
            Classroom Tutorial
          </NavLink>
        </div>

        <div className="mx-auto mt-2 text-white font-bold text-left w-10/12">
          <NavLink to={"/"} className="text-left w-full text-sm">
            Classroom Authorized User’s List
          </NavLink>
        </div>
      </FeatureGuard>
    </Drawer>
  );
}

export default memo(Sidebar);
