import {
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Provider as ReduxStoreProvider } from "react-redux";
import { SnackbarProvider } from "notistack";
import App from "./App";
import "./index.css";
import { MUIThemeOptions, SnackbarProviderSettings } from "./themeConfig";
import { store } from "./redux/store";
import ScrollToTop from "./components/ScrollToTop";
// import reportWebVitals from './reportWebVitals';

//Create theme
let theme = createTheme(MUIThemeOptions);
theme = responsiveFontSizes(theme);

const app = (
  // <React.StrictMode>
  <ReduxStoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider {...SnackbarProviderSettings}>
        <HelmetProvider>
          <Helmet>
            <title>Dikkha</title>
          </Helmet>
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </BrowserRouter>
          </StyledEngineProvider>
        </HelmetProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </ReduxStoreProvider>
  // </React.StrictMode>
);

const root = createRoot(document.getElementById("root") as HTMLElement);
// hydrateRoot(document.getElementById("root") as HTMLElement, app);
root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
