import React from "react";
import OnlineCourseImage from "../../../../assets/images/online_course.svg";
import HomeImage from "../../../../assets/images/home.svg";
import HeadHuntingImage from "../../../../assets/images/head_hunting.svg";
import TuitionJobImage from "../../../../assets/images/png/tuition_job.png";
import LookingForATutor from "../../../../assets/images/png/looking_for_a_tutor.png";
import LookingForATuition from "../../../../assets/images/png/looking_for_a_tuition.png";
import { isMobile } from "react-device-detect";

function Services() {
  return (
    <div className="w-full relative mt-1 md:mt-5">
      {/* <!-- Classroom form Start --> */}
      <form className="absolute z-10 rounded-md left-1/2 -translate-x-1/2 top-[2%] lg:top-[10%] shadow-[0px_0px_14px_2px_theme(colors.green)]">
        <div className="flex flex-row justify-center items-center rounded-md ring-2 ring-primary-dark bg-primary-dark">
          <input
            className="px-5 py-2 w-40 rounded-tl-md rounded-bl-md min-w-[10rem] md:min-w-[15rem] xl:min-w-[35rem] h-12 font-normal text-center"
            type="text"
            placeholder="Enter classroom number"
          />
          <button className="px-5 py-2 rounded-tr-md rounded-br-md bg-primary-dark text-white relative min-w-[8rem] text-sm font-bold">
            Enter Class
          </button>
        </div>
      </form>
      {/* <!-- Classroom form End --> */}

      {/* <!-- SVG Background Start --> */}
      <svg
        className="mt-5 md:mt-2 fill-primary-dark"
        // viewBox="0 0 1920 1040"
        viewBox={isMobile ? "0 0 1920 500" : "0 0 1920 1040"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter
            id="filter0_d_14_150"
            x="-10"
            y="0"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.670588 0 0 0 0 0.415686 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_14_150"
              result="shape"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_14_150"
            />
          </filter>
        </defs>
        <g filter="url(#filter0_d_14_150)">
          <path d="M1920 1032H0V12C266.5 122 660 121 1237 138.5C1695.29 152.4 1920 225 1920 225V1032Z" />
        </g>
      </svg>
      {/* <!-- SVG Background End --> */}

      {isMobile ? (
        <div className="w-full bg-primary-dark px-5">
          <div className="flex justify-center">
            <button className="text-white bg-green px-24 py-6 flex items-center rounded-xl mb-5 min-w-full">
              <img src={TuitionJobImage} alt="Tuition Job" />
              <div className="text-xl font-semibold mx-5">Tuition Job</div>
            </button>
          </div>
          <div className="flex justify-center">
            <button className="text-white bg-primary-light px-24 py-6 flex items-center rounded-xl mb-5 min-w-full">
              <img src={LookingForATutor} alt="Looking For a Tutor" />
              <div className="text-xl font-semibold mx-5">
                Looking For A Tutor?
              </div>
            </button>
          </div>
          <div className="flex justify-center">
            <button className="text-white bg-secondary-light px-24 py-6 flex items-center rounded-xl mb-5 min-w-full">
              <img src={LookingForATuition} alt="Tuition Job" />
              <div className="text-xl font-semibold mx-5">
                Looking For Tuition?
              </div>
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full pt-10 pb-32 bg-primary-dark z-20 absolute top-[45%] md:top-[30%]">
          <div className="container mx-auto grid gap-x-16 gap-y-28 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 bg-primary-dark px-4 md:px-12">
            <div className="relative h-[26.875rem] bg-white rounded-3xl mx-2 lg:mx-0 mb-8">
              <div className="flex flex-row justify-between items-center left-1/2 -translate-x-1/2 -top-[11%] absolute w-[6.25rem] h-[6.25rem] bg-secondary-light rounded-[50%]">
                <img
                  className="w-full object-contain p-6 flex flex-row justify-between items-center"
                  src={OnlineCourseImage}
                  alt="online_course"
                />
              </div>
              <div className="p-5 mt-24 flex flex-col items-center justify-between h-1/2">
                <div className="text-center text-3xl text-primary-dark font-medium mb-2">
                  Online Tuition
                </div>
                <div className="text-center text-grey-300 font-normal text-xl">
                  Simple and secure control of your organization's financial and
                  legal transactions. Send customized invoices and contracts
                </div>
              </div>
              <div className="flex flex-row justify-between items-center h-14 left-1/2 -translate-x-1/2 -bottom-[18%] absolute w-64 bg-secondary-light rounded-[1.25rem]">
                <div className="w-full text-center font-semibold text-2xl text-white">
                  Online Tuition
                </div>
              </div>
            </div>

            <div className="relative h-[26.875rem] bg-white rounded-3xl mx-2 lg:mx-0 mb-8">
              <div className="flex flex-row justify-between items-center left-1/2 -translate-x-1/2 -top-[11%] absolute w-[6.25rem] h-[6.25rem] bg-primary-light rounded-[50%]">
                <img
                  className="w-full object-contain p-6 flex flex-row justify-between items-center"
                  src={HomeImage}
                  alt="home"
                />
              </div>
              <div className="p-5 mt-24 flex flex-col items-center justify-between h-1/2">
                <div className="text-center text-3xl text-primary-dark font-medium mb-2">
                  Home Tuition
                </div>
                <div className="text-center text-grey-300 font-normal text-xl">
                  Schedule and reserve classrooms at one campus or multiple
                  campuses. Keep detailed records of student attendance
                </div>
              </div>
              <div className="flex flex-row justify-between items-center h-14 left-1/2 -translate-x-1/2 -bottom-[18%] absolute w-64 bg-primary-light rounded-[1.25rem]">
                <div className="w-full text-center font-semibold text-2xl text-white">
                  Home Tuition
                </div>
              </div>
            </div>

            <div className="relative h-[26.875rem] bg-white rounded-3xl mx-2 lg:mx-0 mb-8 col-span-1 md:col-span-2 lg:col-span-1 w-auto md:w-[45%] md:mx-auto lg:w-auto">
              <div className="flex flex-row justify-between items-center left-1/2 -translate-x-1/2 -top-[11%] absolute w-[6.25rem] h-[6.25rem] bg-green rounded-[50%]">
                <img
                  className="w-full object-contain p-6 flex flex-row justify-between items-center"
                  src={HeadHuntingImage}
                  alt="head_hunting"
                />
              </div>
              <div className="p-5 mt-24 flex flex-col items-center justify-between h-1/2">
                <div className="text-center text-3xl text-primary-dark font-medium mb-2">
                  Tuition Jobs
                </div>
                <div className="text-center text-grey-300 font-normal text-xl">
                  Automate and track emails to individuals or groups. Skilline’s
                  built-in system helps organize your organization{" "}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center h-14 left-1/2 -translate-x-1/2 -bottom-[18%] absolute w-64 bg-green rounded-[1.25rem]">
                <div className="w-full text-center font-semibold text-2xl text-white">
                  Tuition Job
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Services;
