import React from "react";

export interface IOpinionProps {
  image: string;
  imageName: string;
  rating: number;
  comment: string;
  reviewCount?: number;
  reviewSource?: string;
}

function Opinion({
  image,
  imageName,
  comment,
  rating,
  reviewCount = 0,
  reviewSource = "",
}: IOpinionProps) {
  return (
    <>
      <img
        className="bg-white object-contain rounded-3xl max-w-[15rem] lg:max-w-[30rem]"
        src={image}
        alt={imageName}
      />
      <div className="text-justify py-5 pl-2 pr-5 absolute h-40 lg:h-64 bg-white rounded-xl top-52 lg:top-[27rem] left-[3.5rem] right-6 shadow-[0px_0px_23px_-7px_theme(colors.grey.100)] border-l-8 border-secondary-light flex flex-col items-center justify-between overflow-auto">
        <div className="relative text-grey-300 leading-7 text-sm ml-10 before:content-[''] before:absolute before:h-28 before:-left-[6%] before:w-[1px] before:bg-grey-200">
          {comment}
        </div>
        <div className="flex flex-row items-center justify-between w-full mt-8">
          <div className="font-semibold text-grey-300 text-lg ml-10">
            Gloria Rose
          </div>
          <div className="text-grey-300 text-md text-right">
            <div>
              {[...Array(rating)].map((el, index) => (
                <i key={index} className="fa-solid fa-star text-green"></i>
              ))}
            </div>
            <div className="text-grey-300 text-sm">
              {reviewCount} reviews at {reviewSource}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Opinion;
