import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../../assets/images/logo.svg";

function Navbar() {
  return (
    <div className="fixed w-screen bg-white p-2 rounded-bl-[0.625rem] rounded-br-[0.625rem] shadow-[0_-1px_15px_-3px_theme(colors.green)] z-50 top-0">
      {/* <!-- Large Device Navigation Start --> */}
      <div className="flex flex-row items-center justify-between container mx-auto px-4 md:px-12">
        <Link to="/home">
          <img
            className="max-w-[6.375rem] object-contain"
            src={Logo}
            alt="Dikkha"
          />
        </Link>
        <nav className="hidden lg:block">
          <ul className="flex flex-row items-center justify-between">
            <li className="dropdown-menu relative flex flex-row items-center justify-between mr-6 hover:cursor-pointer">
              <div className="mr-4">How It Works</div>
              <i className="fa-solid fa-chevron-down duration-300 self-center block peer-hover:rotate-180"></i>
              <ul className="bg-white absolute top-10 -left-2 min-w-[15rem] rounded-[0.625rem] shadow-[0px_0px_10px_theme(colors.grey.100)] duration-300 max-h-0 overflow-hidden">
                <li className="p-2 border-b-2 border-grey-100 hover:bg-primary-dark hover:text-white">
                  <a className="w-full block" href="/">
                    How To Request For A Tutor
                  </a>
                </li>
                <li className="p-2 border-b-2 border-grey-100 hover:bg-primary-dark hover:text-white">
                  <a className="w-full block" href="/">
                    Estimated Tuition Fees
                  </a>
                </li>
                <li className="p-2 hover:bg-primary-dark hover:text-white">
                  <a className="w-full block" href="/">
                    FAQ
                  </a>
                </li>
              </ul>
            </li>
            {/* <li className="flex flex-row items-center justify-between h-10 min-w-[10rem] rounded-[0.625rem] shadow-[0px_0px_10px_theme(colors.grey.100)] text-center mr-6">
              <Link className="w-full block" to="/auth/register">
                Register
              </Link>
            </li> */}
            <li className="flex flex-row items-center justify-between h-10 min-w-[10rem] rounded-[0.625rem] shadow-[0px_0px_10px_theme(colors.grey.100)] text-center">
              <Link className="w-full block" to="/auth/login">
                Login
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* <!-- Large Device Navigation End --> */}

      {/* <!-- Small and Medium Device Navigation Start --> */}
      <div className="lg:hidden">
        <input className="hidden peer" type="checkbox" id="main-nav-toggle" />
        <label
          htmlFor="main-nav-toggle"
          className="fixed top-5 right-5 hover:cursor-pointer z-50"
        >
          <i className="fa-solid fa-bars text-xl"></i>
        </label>
        <div className="z-50 duration-300 -left-full peer-checked:left-0 fixed top-0 h-screen bg-white min-w-[15rem] max-w-[15rem] border-r border-grey-100">
          <nav className="p-1 mt-5">
            <ul>
              <li className="my-3 p-1 w-full">
                <div className="w-full flex flex-row flex-wrap">
                  <input
                    className="hidden peer"
                    type="checkbox"
                    id="how-it-works"
                  />
                  <label
                    className="hover:cursor-pointer w-11/12"
                    htmlFor="how-it-works"
                  >
                    How It Works
                  </label>
                  <i className="fa-solid fa-chevron-down duration-300 self-center block peer-checked:rotate-180"></i>
                  <ul className="overflow-hidden duration-300 max-h-0 peer-checked:max-h-[1000rem] ">
                    <li className="py-1 pl-5 my-3">
                      <a className="w-full block" href="/">
                        How To Request For A Tutor
                      </a>
                    </li>
                    <li className="pl-5 my-3">
                      <a className="w-full block" href="/">
                        Estimated Tuition Fees
                      </a>
                    </li>
                    <li className="pl-5 my-3">
                      <a className="w-full block" href="/">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="my-3 p-1">
                <Link className="w-full block" to="/auth/register">
                  Register
                </Link>
              </li>
              <li className="my-3 p-1">
                <Link className="w-full block" to="/auth/login">
                  Login
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* <!-- Small and Medium Device Navigation End --> */}
    </div>
  );
}

export default Navbar;
