export const BaseURL = process.env.REACT_APP_BASEURL;
export const USER_TOKEN = "dikkha_access_token";
export const NOT_FOUND_REDIRECT_TIMEOUT = 5; //In seconds;

export const Colors = {
    background: {
        paper: '#fafdff'
    },
    primary: {
        main: '#144B89',
        light: '#00A3FE',
        dark: '#002347',
        contrastText: '#fff'
    },
    secondary: {
        main: "#00AB6A",
        light: '#00AB6A',
        dark: '#00AB6A',
        contrastText: '#fff'
    },
    error: {
        main: '#de0d0d',
        light: '#E43D3D',
        dark: '#9B0909',
        contrastText: '#fff'
    },
    warning: {
        main: '#ede209',
        light: '#F0E73A',
        dark: '#A59E06',
        contrastText: '#fff'
    },
    info: {
        main: '#0e86e6',
        light: '#3E9EEB',
        dark: '#095DA1',
        contrastText: '#fff'
    },
    success: {
        main: '#26ad2c',
        light: '#51BD56',
        dark: '#1A791E',
        contrastText: '#fff'
    },
    divider: '#e5e5e5',
    white: {
        main: '#fff',
        contrastText: '#000'
    },

}

//WINDOW SIZES
export const WINDOW_LARGE = "LARGE";
export const WINDOW_MOBILE = "MOBILE";
export const WINDOW_TABLET = "TABLET";
export type WindowSize = typeof WINDOW_LARGE | typeof WINDOW_MOBILE | typeof WINDOW_TABLET;

// FORM TYPE
export const LOGIN_FORM = "LOGIN";
export const FORGOT_PASSWORD_FORM = "FORGOT_PASSWORD";
export const RESET_PASSWORD_FORM = "RESET_PASSWORD"
export type AuthFormType = typeof LOGIN_FORM | typeof FORGOT_PASSWORD_FORM | typeof RESET_PASSWORD_FORM;

// USER TYPE
export const TEACHER = "teacher";
export const STUDENT = "student";
export type UserType = typeof TEACHER | typeof STUDENT;

//JOB TYPES
export const APPLIED_JOBS = "applied_jobs";
export const OFFERED_JOBS = "offered_jobs";
export const ACTIVE_JOBS = "active_jobs";
export const COMPLETED_JOBS = "completed_jobs";
export const PENDING_JOBS = "pending_jobs";
export const APPROVED_JOBS = "approved_jobs";
export type JobType = typeof APPLIED_JOBS | typeof OFFERED_JOBS | typeof OFFERED_JOBS | typeof ACTIVE_JOBS | typeof COMPLETED_JOBS | typeof PENDING_JOBS | typeof APPROVED_JOBS;

export const JOB_TYPE_URL_MAP = {
    [APPLIED_JOBS]: {
        title: "Applied Jobs",
        url: "applied-jobs"
    },
    [OFFERED_JOBS]: {
        title: "Offered Jobs",
        url: "offered-jobs"
    },
    [ACTIVE_JOBS]: {
        title: "Active Jobs",
        url: "active-jobs"
    },
    [COMPLETED_JOBS]: {
        title: "Completed Jobs",
        url: "completed-jobs"
    },
    [PENDING_JOBS]: {
        title: "Pending Jobs",
        url: "pending-jobs"
    },
    [APPROVED_JOBS]: {
        title: "Approved Jobs",
        url: "approved-jobs"
    }
}