import React, { useState, createElement, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import SidebarNavigation from "..";
import { INavigation } from "../../../../../navigation";
import { IFlatData } from "../../../../../utils/TreeFlattener";

const SidebarItem = ({
  data,
  selectedNavPath,
}: {
  data: INavigation;
  selectedNavPath: IFlatData<INavigation>[];
}) => {
  const level =
    selectedNavPath?.find((e) => e.item.id === data?.id)?.level || 0;
  const location = useLocation();

  const hasChild = data.children?.length ? true : false;

  const [open, setOpen] = useState(false);

  const setOpenNav = (): boolean => {
    const navPath = selectedNavPath?.find(
      (e) => e.item.url === location.pathname
    );
    if (
      navPath?.path
        ?.slice(0, navPath.path.length - 1)
        .find((e: any) => e === data?.id)
    ) {
      return true;
    }
    return false;
  };

  const isNavOpen = setOpenNav();

  useEffect(() => {
    if (isNavOpen) setOpen(true);
    else setOpen(false);
  }, [isNavOpen, open]);

  const matchUrl = () => {
    if (data.isChild) return location.pathname === data?.url;
    else return "/" + location.pathname.split("/")[1] === data?.url;
  };

  const handleClick = () => {
    setOpen((o) => !o);
  };

  const getIcon = (icon: any) => {
    return createElement(icon.class, icon.props);
  };

  const listItemStyle: SxProps = {
    height: "1.8rem",
    px: 1,
    borderRadius: "0.35rem",
    minWidth: "13rem",
    color: "white",
    "& .MuiTypography-root": {
      fontWeight: 400,
      fontSize: "0.8rem",
    },
    "&:hover": {
      backgroundColor: "white",
      color: "primary.main",
      "& .MuiListItemIcon-root": {
        color: "primary.main",
      },
      "& .MuiTypography-root": {
        fontWeight: 600,
        fontSize: "0.8rem",
      },
    },
  };

  const styles: { [key: string]: SxProps } = {
    listItem: { "&.MuiListItem-root": listItemStyle },
    listItemActive: {
      "&.MuiListItem-root": {
        ...listItemStyle,
        backgroundColor: "white",
        color: "primary.main",
        "& .MuiTypography-root": {
          fontSize: "0.8rem",
          fontWeight: 600,
        },
      },
    },
    listIconActive: matchUrl() ? { color: "primary.main" } : { color: "white" },
  };

  return (
    <Box sx={{ mt: 0.5 }}>
      {hasChild ? (
        <>
          <ListItem
            button
            onClick={handleClick}
            id={data.id}
            sx={styles.listItem}
          >
            <ListItemIcon sx={{ ml: level + 1, minWidth: "2.5rem" }}>
              {data?.icon && getIcon(data.icon)}
            </ListItemIcon>
            <ListItemText>{data.title}</ListItemText>
            <ListItemIcon sx={{ minWidth: "auto" }}>
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </ListItemIcon>
          </ListItem>
          <SidebarNavigation
            navigationData={data.children!}
            selectedNavPath={selectedNavPath}
            isSubmenu={true}
            open={open}
          />
        </>
      ) : (
        <NavLink to={data?.url || ""}>
          <ListItem
            button
            id={data.id}
            sx={matchUrl() ? styles.listItemActive : styles.listItem}
          >
            {data?.icon && (
              <ListItemIcon
                sx={{
                  ml: level + 1,
                  minWidth: "2rem",
                  ...styles.listIconActive,
                }}
              >
                {getIcon(data.icon)}
              </ListItemIcon>
            )}
            <ListItemText>{data.title}</ListItemText>
          </ListItem>
        </NavLink>
      )}
    </Box>
  );
};

export default SidebarItem;
