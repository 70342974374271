import React from "react";
import { Avatar as MuiAvatar, SxProps } from "@mui/material";
import DefaultTeacherImage from "../../assets/images/png/teacher-default.png";
import { useAppSelector } from "../../redux/hooks";

function Avatar({ styles }: { styles?: SxProps }) {
  const userState = useAppSelector((state) => state.user);
  const avatarImage = userState.data?.photo || DefaultTeacherImage;
  const avatarName = userState.data?.first_name || "user";

  return <MuiAvatar sx={styles} alt={avatarName} src={avatarImage} />;
}

export default Avatar;
