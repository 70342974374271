import React from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../../../assets/images/header_image.svg";
import HeaderImageMobile from "../../../../assets/images/header_image_mobile.svg";
import { isMobile } from 'react-device-detect';

function Header() {
  return (
    <section className="pt-16">
      {isMobile && (
        <div className="flex justify-center mt-2">
          <img src={HeaderImageMobile} alt="" />
        </div>
      )}
      <div className="container mx-auto w-full flex flex-row items-end justify-between mt-10 md:mt-16 lg:mt-36 px-4 md:px-12">
        <div className="flex flex-col items-start justify-between w-full md:h-48 lg:h-96">
          <div className="w-full text-center md:text-left text-primary font-bold text-[2rem] lg:text-[3rem]">
            Find the best{" "}
            <span className="text-green text-[2rem] lg:text-[3rem]">tutor</span>{" "}
            for online or home
          </div>
          <div className="w-full flex flex-col items-start justify-between text-center md:text-left">
            <div className="w-full mb-2 mt-5">
              <a
                href="/"
                className="mx-auto md:mx-0 flex flex-row justify-center items-center w-64 h-14 md:w-72 md:h-16 bg-primary-light rounded-3xl text-white font-semibold"
              >
                Looking For A Tutor?
              </a>
            </div>
            <div className="w-full text-primary font-semibold">
              Want to become a tutor?{" "}
              <Link className="text-primary-light italic" to="/auth/register">
                Register
              </Link>
              &nbsp;Now
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <img
            className="object-contain 2xl:max-w-[964px] xl:max-w-[800px] lg:max-w-[600px] md:max-w-[400px]"
            src={HeaderImage}
            alt="Dikkha"
          />
        </div>
      </div>
    </section>
  );
}

export default Header;
